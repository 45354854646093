import React from "react"

import Layout from "../components/layout-fr"
import SEO from "../components/seo"
import GoogleMapReact from "google-map-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarker } from "@fortawesome/free-solid-svg-icons"

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      textAlign: "center",
      opacity: ".8",
    }}
  >
    <FontAwesomeIcon icon={faMapMarker} size="3x" />
  </div>
)

const LocationPage = () => (
  <Layout>
    <SEO title="Localisation" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Localisation</h1>
          <h2 class="subtitle">Comment nous trouver</h2>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column is-1" />
        <div class="column">
          <div class="content">
            <p>
              La ville d’Alcobaça se trouve à 100 km au nord de Lisbonne. Elle
              est desservie par 2 autoroutes (A1 et A8) et par plusieurs routes
              nationales.{" "}
            </p>
            <p>
              Silveira est située à 3 km au sud d’Alcobaça sur la nationale N
              8-6 en direction d’Évora de Alcobaça, Turquel, Benedita.{" "}
            </p>
            <p>
              A partir de Silveira, il est facile de rayonner et d’alterner
              visites du patrimoine historique et culturel (monastères
              d’Alcobaça et de Batalha, château de Leiria, ville fortifiée
              d’Óbidos), baignades dans l’océan ou randonnées dans le parc
              naturel des Serras de Aire et Candeeiros.
            </p>
            <h4>Caravanes et camping-cars</h4>
            <p>
              Nous recommandons au caravanes et aux camping-cars, surtout s'ils
              sont longs, d'entrer dans le camping en ayant le portail sur leur
              droite. Donc, si vous arrivez d'Alcobaça et que l'entrée se trouve
              sur votre gauche, continuez votre route jusqu'au premier rond-pont
              (1 km) et faites demi-tour.{" "}
            </p>
            <p><strong>La réception est ouverte de 8:30 à 20:30.</strong></p>
            <br></br>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-image">
              <div style={{ height: "50vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBD0DW9vSvjU7XrcHvhuRG2OQmTGmJonqg",
                  }}
                  defaultCenter={{
                    lat: 39.527153,
                    lng: -8.964215,
                  }}
                  defaultZoom={12}
                >
                  <AnyReactComponent
                    lat={39.527153}
                    lng={-8.964215}
                    text="Camping Silveira"
                  />
                </GoogleMapReact>
              </div>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Adresse</p>
                </div>
              </div>
              <div class="content">
                <p>
                  Silveira, Capuchos <br />
                  2460-479 Alcobaça <br />
                  Portugal
                </p>
                <a
                  href="https://goo.gl/maps/uwZkUvcQJRShSh7G6"
                  class="button is-primary"
                >
                  Google Maps
                </a>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-1" />
      </div>
    </div>
  </Layout>
)

export default LocationPage
